export const getRedirectUrl = () => {
  return typeof window !== "undefined"
    ? `${window?.location?.origin}/oneauth/authorization`
    : "";
};

export function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

export function base64urlencode(a) {
  let str = "";
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

const generateCodeChallengeFromVerifier = async (v) => {
  const hashed = await sha256(v);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
};
export const generateCodeChallenge = async () => {
  const code_verifier = localStorage.getItem("code_verifier");
  const val = await generateCodeChallengeFromVerifier(code_verifier);
  return val;
};
