/** @jsx jsx */
import { useCallback } from "react";
import { jsx, Badge } from "theme-ui";
import {
  DefaultButton,
  Stack,
  StackItem,
  IconButton,
} from "office-ui-fabric-react";
import { useDispatch } from "react-redux";
import { showAlert } from "gatsby-plugin-hfn-profile/state/actions";
import {
  NEWPRACTITIONER,
  PRACTITIONER,
  SEEKER,
  TRAINER,
  WHAT_IS_MEDITATION_LINK,
  HFN_REGULAR_CARD_LINK,
  PRECEPTORS_RESOURCES_LINK,
  FOLLOW_UP_LINK,
  TEN_MAXIMS_VIDEO_LINK,
} from "sites-common/utils/validations";
import PropTypes from "prop-types";

const colors = require("./colors.json");

function LevelBadge({ abhyas_stage, newPractitionerChildren, bg }) {
  const dispatch = useDispatch();
  const aStage = abhyas_stage;

  const showCallout = useCallback(() => {
    dispatch(
      showAlert({
        title: "Order Printed ID Card",
        message:
          "Once the ID order is placed, it will take a minimum of 30 days to dispatch to your address.\nCurrently, there is no option to place an order for ID cards to overseas locations. We apologize for any inconvenience this may cause and are working on a solution.",
      })
    );
  }, [dispatch]);

  return (
    <div sx={{ bg: bg || colors.shade1, pt: 1, pb: 3 }}>
      <div sx={{ mb: 1 }}>
        <Badge sx={{ bg: aStage === TRAINER ? "red" : colors.shade2 }}>
          HEARTFULNESS {aStage.toUpperCase()}
        </Badge>
      </div>
      {aStage === TRAINER && (
        <div sx={{ fontSize: "0.8em", fontWeight: 400, px: 2 }}>
          {/* I am a Heartfulness Trainer (Preceptor)! I am a practitioner on the
          path of Sahaj Marg and have voluntarily taken up the duty of training
          others in the practices of Heartfulness and by imparting Yogic
          Transmission! */}

          <DefaultButton
            sx={{ my: 2 }}
            target="_blank"
            href="/trainer/live"
            text="📺 Live Channel for Preceptor Event"
          />
          <DefaultButton
            sx={{ my: 2 }}
            target="_blank"
            href={PRECEPTORS_RESOURCES_LINK}
            text="🎯 View Preceptor Resources"
          />
        </div>
      )}
      {aStage === SEEKER && (
        <div sx={{ fontSize: "0.8em", fontWeight: 400, px: 2 }}>
          Congratulations on taking the first step in the Heartfulness journey!
          Learn the meditation practice and the benefits of meditation. Advance
          to the next level in Heartfulness practice by completing three
          meditation sessions either with a trainer or online, and register as a
          practitioner in the section below.
          <DefaultButton
            sx={{ my: 2 }}
            target="_blank"
            href={WHAT_IS_MEDITATION_LINK}
          >
            Learn Meditation Practice
          </DefaultButton>
        </div>
      )}
      {aStage === PRACTITIONER && (
        <div sx={{ fontSize: "0.8em", fontWeight: 400, px: 2 }}>
          I am a practitioner on the path of Sahaj Marg. I strive to propel in
          my spiritual journey by deepening my practice and following all
          elements of Heartfulness practices.
          <Stack sx={{ mt: 3 }} tokens={{ childrenGap: 5 }}>
            <StackItem>
              <DefaultButton
                target="_blank"
                href={FOLLOW_UP_LINK}
                text="🧘 Heartfulness Meditation Practices"
              />
            </StackItem>
            <StackItem>
              <DefaultButton
                target="_blank"
                href={TEN_MAXIMS_VIDEO_LINK}
                text="🎯 Ten Maxims Video"
              />
            </StackItem>
          </Stack>
        </div>
      )}
      {aStage === NEWPRACTITIONER && (
        <div sx={{ fontSize: "0.8em", fontWeight: 400, px: 2 }}>
          Congratulations on starting the meditation practice. Learn and
          practice all the elements of the Heartfulness Meditation.
          <div sx={{ mb: 3, mt: 1 }}>
            <DefaultButton
              target="_blank"
              href={FOLLOW_UP_LINK}
              text="🧘 Heartfulness Meditation Practices"
            />
          </div>
          If you have been practicing regularly for at least six months, you can
          apply for a Regular Heartfulness ID number.
          <div sx={{ mt: 1 }}>
            {newPractitionerChildren || (
              <DefaultButton
                text="Apply for Regular Heartfulness ID"
                iconProps={{ iconName: "IDBadge" }}
                href="/profile"
              />
            )}
          </div>
        </div>
      )}
      {(aStage === PRACTITIONER || aStage === TRAINER) && (
        <Stack horizontal verticalAlign="center">
          <StackItem>
            <DefaultButton
              sx={{ m: 2 }}
              href={HFN_REGULAR_CARD_LINK}
              target="_blank"
              text="Order Printed ID Card"
              iconProps={{ iconName: "IDBadge" }}
            />
          </StackItem>
          <StackItem>
            <IconButton
              iconProps={{
                iconName: "Info",
              }}
              onClick={showCallout}
              sx={{ cursor: "pointer", mr: 1 }}
            />
          </StackItem>
        </Stack>
      )}
    </div>
  );
}
LevelBadge.propTypes = {
  abhyas_stage: PropTypes.string.isRequired,
  newPractitionerChildren: PropTypes.element,
  bg: PropTypes.string,
};
LevelBadge.defaultProps = {
  newPractitionerChildren: null,
  bg: undefined,
};

export default LevelBadge;
